
body {
    height: 100%;
    font-family: "PingFang SC","Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#root {
    min-height: 100%;
    background-color: #f3f4f7;
}
.hide{
    display: none;
}
#home-layout .menu-trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#home-layout .menu-trigger:hover {
    color: #1890ff;
}

#home-layout .logo-container {
    height: 54px;
    /*background: #ddd;*/
    /*margin: 16px;*/
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home-layout .content-container {
    min-height: 280px;
    margin-top: 0px;
    background-color: #fff;
}

#home-layout .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#home-layout .header-container .header-left {
    display: inherit;
    justify-content: flex-start;
}

#home-layout .header-container .header-right {
    display: inherit;
    justify-content: flex-end;
}

#home-layout .header-container .header-right .account-entry {
    margin-right: 26px;
    cursor: pointer;
}
.logo-large-w {
    display: block;
    width: 124px;
    height: 44px;
    background-image: url(../../assets/logo_app.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.logo-small-w {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../../assets/logo_app.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.logo-large {
    display: block;
    width: 101px;
    height: 18px;
    background-image: url(../../assets/logo.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.logo-small {
    display: block;
    width: 18px;
    height: 18px;
    background-image: url(../../assets/logo36.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.table-row-drop-over {
    background-color: #FF000022;
}


#home-layout .side-menus {
    height: 100vh;
    /*width: 240px!important;*/
    /*max-width: unset!important;*/
    position: fixed;
    overflow: scroll;
    z-index: 2;
}

.page-content-wrapper {
    margin: 24px;
    border-radius: 4px;
    overflow: hidden;
    /*background-color: #FFF;*/
}

.page-header-wrapper {
    /*background-color: #FFF;*/
}

#home-layout .fixed-header {
    background: #fff;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.table-title-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
}
.react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
}

.tree-node-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tree-node-item .title {
    min-width: 200px;
}

.tree-node-item .actions {
}

.tree-title-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding: 0 24px;
}

.layout-editor-item {
    display: flex;
    flex-direction: column;
    padding: 4px;
    background-color: #EEE;
    border-radius: 2px;
}

.editor-item-hidden {
    opacity: 0.4;
}

.layout-editor-item-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    align-items: center;
}

.layout-editor-item-label {
    line-height: 40px;
    font-weight: bold;
}

.layout-display-item {
    display: flex;
    flex-direction: column;
    padding: 4px;
    background-color: #EEE;
    border-radius: 2px;
}

.layout-display-item-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
}

.layout-display-item-label {
    font-weight: bold;
    font-size: 12px;
}
.layout-display-item-bottom {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.table-operations {
    margin:8px
}
/*.ant-layout-sider{*/
/*    background-color: #001529!important;*/
/*}*/
.ant-form-item{
    margin-bottom: 14px!important;
}


.isOutlinedStyle {
    /*padding-top:24px;*/
    /*padding-left:24px;*/
    /*padding-right:24px;*/
    /*height:60px;*/
    overflow: hidden;
    background-color: #fff;
}
.userInfo{
    width: 240px;
    /*height: 40px;*/
    padding: 14px 24px;
    position: fixed;
    bottom: 0;
    left: 0;
    color: #999!important;
    background-color: #001529;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;

}
.barBox{
    flex: 0 1 auto;
    /*height: 100%;*/
    height: 100vh;
    width: 0px;
    position: fixed;
    z-index: 1;
}
.sliderCollapsedBar{
    width: 0;
    height: 64px;
    position: absolute;
    top: calc(50% - 32px);
    right: -27px;
    z-index: 99;
    line-height: 46px;
    border: 9px solid transparent;
    border-left: 20px solid #EBEBEB;
    cursor: pointer;
    color: #BFBFBF;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
/*}*/

}
.barIcon{
    width: 12px;
    position: relative;
    left: -17px;
    color: #8d8d8d;
    font-size: 18px;
    cursor: pointer;
    opacity: 0.5;

}

.commonStyle {
    /*padding-top:24px;*/
    /*padding-left:24px;*/
    /*padding-right:24px;*/
    /*height:60px;*/
    /*overflow: hidden;*/
    background-color: #fff;

}
.leftTree{
    /*max-height: calc(100vh - 90px) ;*/
    width: 160px;
    background-color: #fafafa;
    margin-right: 15px;
    /*flex: 200px;*/
    overflow-y: auto;
    z-index: 2;
    /*padding: 24px 18px;*/
}
.leftRightPageLeftList {
    height: calc(100vh - 100px);
    margin-right: 15px;
    z-index: 2;
    width: 250px;
    border-right: #eeeeee solid 1px;
    margin-right: 15px;
    overflow-y: auto;
}
.leftRightPageRightDetail {
    height: calc(100vh - 100px);
    border-right: #eeeeee solid 1px;
    margin-right: 15px;
    overflow-y: auto;
}
.rightTable{
    display: flex;
    flex:auto;
    flex-direction: column;
}
.feedbackLeft{
    height: calc(100% - 50px);
    overflow-x: hidden;
}
.feedbackRight{
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
}

.feedbackFunction{
    width: 100%;
    text-align: right;
    /*border-top: #ddd solid 1px;*/
    padding-top: 5px;
}
.rce-mbox{
    padding: 18px!important;
}
.rce-citem-status{
    width: 300px!important;
    height: 100%!important;;
    left: 0!important;;
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #999!important;;
    opacity: 0.1!important;
    border-radius: 0% !important;
    top: 0;
    bottom: 0;
}
.chatListRight{
    /* margin: 0 24px!important; */
    height: calc(100% - 100px);
    overflow: auto;
    padding-bottom: 50px;
}


/*库存相关*/
.leftInfo{
    width: 300px;
    height: 100vh;
}
.rightBox{
    display: flex;
    flex:auto;
    flex-direction: column;
}
.stockInfoList{
    padding-top: 22px;
}
.stockInfoList span{
    color: #999;
    width: 72px;
    display: inline-block;
}

/** flow-editor **/
.flow-editor {
    position: relative;
    /*left: 0;*/
    /*top: 64px;*/
    /*right: 0;*/
    /*bottom: 0;*/
    z-index: 11;
    background-color: #f0f0f2
}

.flow-editor .root-node-wrap {
    display: flex;
    padding: 50px 0 0
}

.root-editor {
    position: relative;
    height: 100%
}

.root-editor .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, .3)
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
    z-index: 20
}

.flow-editor.flow-editor {
    position: relative;
    /*left: 0;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*z-index: 11;*/
    background-color: #f0f0f2
}

.flow-editor.flow-editor .root-node-wrap {
    display: flex;
    padding: 50px 0 0
}

.flow-editor-node div {
    box-sizing: border-box
}

.flow-editor-node.has-error .flow-editor-node-container {
    border: 1px solid #ff5b4c;
    box-shadow: 0 0 3px 0 #ff5b4c
}

.flow-editor-node .icon-modal-warning {
    position: absolute;
    right: -31px;
    font-size: 23px;
    color: #ff5b4c
}

.flow-editor-node .flow-editor-node-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 200px;
    border-radius: 8px;
    overflow: hidden
}

.flow-editor-node .flow-editor-node-container .node-title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 3px 13px
}

.flow-editor-node .flow-editor-node-container .node-title-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.flow-editor-node .flow-editor-node-container .node-title-priority {
    flex-shrink: 0;
    margin-left: 5px
}

.flow-editor-node .flow-editor-node-container .node-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    margin: 0 4px 4px;
    padding: 8px;
    background: #fff;
    border-radius: 4px;
    min-height: 38px;
    font-size: 14px;
    color: #3e4759;
    cursor: pointer
}

.flow-editor-node .flow-editor-node-container .node-content.node-content-placeholder {
    color: #a1a5ad
}

.flow-editor-node .flow-editor-node-container .node-detail {
    overflow: hidden
}

.flow-editor-node .flow-editor-node-container .node-detail-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.flow-editor-node .flow-editor-node-container > .delete-btn {
    display: none;
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 7px;
    color: #fff;
    cursor: pointer
}

.flow-editor-node .flow-editor-node-container.read-only .delete-btn, .flow-editor-node .flow-editor-node-container.read-only .node-content, .flow-editor-node .flow-editor-node-container > .delete-btn.read-only {
    cursor: not-allowed
}

.flow-editor-node .flow-editor-node-container:hover > .delete-btn {
    display: block
}

.flow-editor-node .flow-editor-node-container .node-delete-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, .7)
}

.flow-editor-node .flow-editor-node-container .node-delete-layer button {
    margin-right: 10px
}

.flow-editor-node .flow-editor-node-container .node-delete-layer button:last-child {
    margin-right: 0
}

.flow-editor-node .flow-editor-node-container .node-delete-layer button.node-delete-cancel {
    color: #fff;
    border-color: #fff
}

.flow-editor-node.approval, .flow-editor-node.condition, .flow-editor-node.end, .flow-editor-node.start {
    position: relative;
    width: 200px;
    height: 150px
}

.flow-editor-node.approval .flow-editor-node-container, .flow-editor-node.condition .flow-editor-node-container, .flow-editor-node.end .flow-editor-node-container, .flow-editor-node.start .flow-editor-node-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    max-height: 92px;
    z-index: 2
}

.flow-editor-node.approval .bottom-v-line, .flow-editor-node.condition .bottom-v-line, .flow-editor-node.end .bottom-v-line, .flow-editor-node.start .bottom-v-line {
    position: absolute;
    bottom: 0;
    left: 99px;
    height: 150px;
    width: 2px;
    background-color: #c2c5cc
}

.flow-editor-node.approval .add-node-btn, .flow-editor-node.condition .add-node-btn, .flow-editor-node.end .add-node-btn, .flow-editor-node.start .add-node-btn {
    position: absolute;
    bottom: 26px;
    left: 89px
}

.flow-editor-node.end .flow-editor-node-container, .flow-editor-node.start .flow-editor-node-container {
    background: #a9b4cd
}

.flow-editor-node.end .node-title, .flow-editor-node.start .node-title {
    color: #fff
}

.flow-editor-node.branch {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
    min-height: 100%
}

.flow-editor-node.branch > .nodes {
    display: flex;
    flex-direction: column;
    align-items: center
}

.flow-editor-node.branch > .bottom-line-mask, .flow-editor-node.branch > .bottom-v-line, .flow-editor-node.branch > .top-line-mask, .flow-editor-node.branch > .top-v-line {
    display: none
}

.flow-editor-node.branch.root-branch {
    margin: 0 auto
}

.flow-editor-node.route .flow-editor-node.branch:first-child > .bottom-line-mask, .flow-editor-node.route .flow-editor-node.branch:first-child > .top-line-mask, .flow-editor-node.route .flow-editor-node.branch:last-child > .bottom-line-mask, .flow-editor-node.route .flow-editor-node.branch:last-child > .top-line-mask {
    display: block;
    position: absolute;
    width: 50%;
    height: 2px;
    background-color: #f0f0f2
}

.flow-editor-node.route .flow-editor-node.branch:first-child > .top-line-mask {
    top: -31px;
    left: 0
}

.flow-editor-node.route .flow-editor-node.branch:first-child > .bottom-line-mask {
    bottom: -2px;
    left: 0
}

.flow-editor-node.route .flow-editor-node.branch:last-child > .top-line-mask {
    top: -31px;
    right: 0
}

.flow-editor-node.route .flow-editor-node.branch:last-child > .bottom-line-mask {
    bottom: -2px;
    right: 0
}

.flow-editor-node.route .flow-editor-node.branch > .top-v-line {
    display: block;
    position: absolute;
    top: -31px;
    left: calc(50% - 1px);
    width: 2px;
    height: 31px;
    background-color: #c2c5cc
}

.flow-editor-node.route .flow-editor-node.branch > .bottom-v-line {
    display: block;
    width: 2px;
    background-color: #c2c5cc;
    flex-grow: 1
}

.flow-editor-node.normal {
    position: relative;
    width: 200px;
    height: 200px
}

.flow-editor-node.normal > .body {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100px;
    border: 1px solid;
    background-color: #f0f0f2
}

.flow-editor-node.normal > .body > .delete-btn {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer
}

.flow-editor-node.normal > .body:hover > .delete-btn {
    display: block
}

.flow-editor-node.normal > .bottom-v-line {
    position: absolute;
    top: 100px;
    left: 99px;
    height: 100px;
    width: 2px;
    background-color: #c2c5cc
}

.flow-editor-node.normal > .add-node-btn {
    position: absolute;
    bottom: 38.5px;
    left: 88.5px
}

.root-node-wrap > .flow-editor-node.branch > .nodes > .flow-editor-node.normal:first-child > .body > .delete-btn {
    display: none
}

.add-node-btn {
    position: relative;
    width: 23px;
    height: 23px;
    border-radius: 50%
}

.add-node-btn.add-node-btn-active, .add-node-btn:hover {
    border: 1px solid #37f;
    box-shadow: 0 0 3px 0 #37f
}

.add-node-btn .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px solid #c1d6ff;
    background-color: #fff;
    color: #37f;
    outline: none;
    cursor: pointer
}

.add-node-btn .add-btn .add-btn-icon {
    font-size: 10px;
    font-weight: 700
}

.add-node-btn .add-btn.read-only {
    cursor: not-allowed
}

.add-node-types {
    display: flex;
    position: absolute;
    top: -4px;
    left: 38px;
    min-width: 200px;
    background-color: #fff;
    padding: 10px 30px;
    z-index: 5;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(56, 73, 69, .11)
}

.add-node-types:before {
    content: "";
    position: absolute;
    top: 8px;
    left: -8px;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent #fff transparent transparent;
    border-style: solid;
    border-width: 8px 8px 8px 0
}

.add-node-types .node-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 35px;
    cursor: pointer
}

.add-node-types .node-type:last-child {
    margin-right: 0
}

.add-node-types .node-type-icon {
    font-size: 46px
}

.add-node-types .node-type-icon.icon-approval-node {
    color: #5b91fe
}

.add-node-types .node-type-icon.icon-condition-node {
    color: #7bddc4
}

.add-node-types .node-type-name {
    margin-top: -8px;
    white-space: nowrap
}

.flow-editor-node.route {
    display: flex;
    flex-direction: column
}

.flow-editor-node.route > .top-h-line {
    height: 2px;
    background-color: #c2c5cc;
    margin-top: 13px;
    margin-bottom: -15px
}

.flow-editor-node.route > .add-branch {
    position: relative;
    height: 28px;
    margin-bottom: 16px;
    border-radius: 14px;
    border: 1px solid transparent;
    align-self: center;
    color: #37f;
    font-size: 12px;
    cursor: pointer;
    z-index: 1
}

.flow-editor-node.route > .add-branch:hover {
    border: 1px solid #37f;
    box-shadow: 0 0 3px 0 #37f
}

.flow-editor-node.route > .add-branch.read-only {
    cursor: not-allowed
}

.flow-editor-node.route > .add-branch .add-branch-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: 4px solid #c1d6ff;
    border-radius: 14px;
    background-color: #fff
}

.flow-editor-node.route > .branches {
    display: flex;
    justify-content: center
}

.flow-editor-node.route > .bottom-h-line {
    height: 2px;
    background-color: #c2c5cc
}

.flow-editor-node.route > .bottom-v-line {
    height: 100px;
    width: 2px;
    background-color: #c2c5cc;
    align-self: center
}

.flow-editor-node.route > .add-node-btn {
    margin-top: -61.5px;
    margin-bottom: 38.5px;
    align-self: center
}

.flow-editor-node.approval {
    position: relative;
    width: 200px;
    height: 150px
}

.flow-editor-node.approval .node-title {
    color: #fff
}

.flow-editor-node.approval .node-title-name {
    min-height: 18px
}

.flow-editor-node.approval .flow-editor-node-container {
    background-color: #5b91fe
}

.flow-editor-node.approval .node-detail .node-detail-item {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.flow-editor-node.approval .node-detail.multiple-items .node-detail-item {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.flow-editor-node.approval:hover .node-title-name {
    margin-right: 8px
}

.root-node-wrap > .flow-editor-node.branch > .nodes > .flow-editor-node.approval:first-child > .body > .delete-btn {
    display: none
}

.addBtnHoverState {
    border: 1px solid #37f;
    box-shadow: 0 0 3px 0 #37f
}

.flow-editor-node.condition .flow-editor-node-container {
    background: #fff
}

.flow-editor-node.condition .node-title {
    color: #2eb795;
    padding-right: 18px;
    border-bottom: 1px solid #f6f6f7
}

.flow-editor-node.condition .node-title-name {
    min-height: 18px
}

.flow-editor-node.condition .node-detail-item {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.flow-editor-node.condition .delete-btn {
    color: #a1a5ad
}

.root-node-wrap > .flow-editor-node.branch > .nodes > .flow-editor-node.condition:first-child > .body > .delete-btn {
    display: none
}

.node-content-text-only {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 35px;
}

.node-content-fullwidth {
    width: 100%;
}

.action-search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.action-search-bar .search-bar {
    flex:1
}

.action-search-bar .actions-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.table-bottom-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    background-color: #fafafa;
}

.table-delete-row {
    opacity: 0.5;
    /*text-decoration-color: orangered !important;*/
    text-decoration:line-through;
    text-decoration-style: solid;
}

/*GoodSaleForecast*/
.totalSummaryCol{
    font-weight: bold;
    color: #000;
    /*background-color: #faf6ef;*/
}
.typeSummaryCol{
    /*background-color: #fafafa;*/
    font-weight: bold;
    color: #000;
    /*border-top: #c7c7c7 solid 1px;*/


}
.forecastColumnStyle{
    background-color: #fafafa;
}

.paymentInfoSummary {
    /*border-top: 1px solid #ddd;*/
    /*padding: 10px 0;*/
}

.paymentInfoSummaryItem {
    height: 30px;
    font-size: 15px;
    font-weight: bold;
}
.ant-table.ant-table-small{
    font-size: 13px!important;
}
.ant-table.ant-table-small td,.ant-table.ant-table-small th{
    padding: 6px 5px!important;
}
.approval-list{
    padding: 20px 0;
    cursor: pointer;
    position: relative;
    transition: opacity 1s ease;
}
.approval-list:hover{
    opacity: 0.9;
}

.approval-arrow{
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -10px;
    font-size: 20px;
    opacity: 0.5;
}
.ant-list-split .ant-list-item:last-child{
    border-bottom: 1px solid #f0f0f0!important;
}
.ant-row{
    /*flex-flow: row!important;*/
}

.noticeInfo{
    padding: 20px 0;
    line-height: 28px;
}
.ant-table-cell{
    word-break: break-word!important;
}

.row-class-gray {
    background-color: #ddd;
}

.row-class-yellow {
    background-color: #fef7ed;
}

.prev-container {
    overflow-y: scroll;
    width: 100%;
    height: 600px;
    padding: 10px;
}

.prev-container-log {
    overflow-y: scroll;
    width: 100%;
    height: 600px;
    padding: 0;
}

.prev-line {
    white-space: normal; /* Since CSS 2.1 */
    word-wrap: break-word;
    background: transparent;
    color: #333;
    font-size: 14px;
}

.orderApply{
    margin: 0 0 15px 0;
    padding-bottom: 15px;
    /*border-top:1px solid #ddd;*/
    border-bottom:1px solid #ddd

}
/*生产订单*/
.ManuSupplierInfo{
    margin-top: 15px;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: #fafafa solid 5px;
}
.ManuSupplierInfo .item{
    margin: 8px 0;
}
.ManuSupplierInfo span.title{
    width: 75px;
    display: inline-block;
    color: #333;
}

.row-fade-background1 {
    animation: fade1 2s forwards;
    background-color:rgba(242, 245, 169, 1);
}
.row-fade-background0 {
    animation: fade0 2s forwards;
    background-color:rgba(242, 245, 169, 1);
}

@keyframes fade1 {
    from {background-color:rgba(242, 245, 169, 1);}
    to {background-color:rgba(242, 245, 169, 0);}
}

@keyframes fade0 {
    from {background-color:rgba(242, 245, 169, 1);}
    to {background-color:rgba(242, 245, 169, 0);}
}

.statistics-container {
    padding: 16px;
    background: #ececec;
}

.cursor-pointer {
    cursor: pointer;
}

.statistic-group-wrapper {
    margin: 0 24px 24px 24px;
}