.orderTemplate{
    width: 980px;
    padding:20px;
}
.selection-panel{
    padding-bottom: 20px;
    /*margin-bottom: 20px;*/
    border-bottom: #ddd solid 1px;
}
.orderTemplate h2{
    font-size: 24px;
    text-align: center;
    padding-right: 100px;

}
.orderTemplate h3{
    font-size: 14px;
    margin-bottom: 10px;
}
.orderTemplate .logo{
    /*position: absolute;*/
    display: inline-block;
    width: 100px;
    height: 18px;
    /*background: url("../../assets/logo.png");*/
    background-size: contain;
    /*left: 0px;*/
    /*top: 40px;*/
    margin-top: 12px;
    float: left;

}
.orderTemplate .note{
    width: 70%;
}
.orderTemplate .signature_box{
    border-bottom: #ddd solid 1px;
    height: 40px;
    line-height: 40px;
}
.signature{
    display: flex;
    margin-top: 50px;
}
.signature .left, .signature .right{
    flex-direction: column;
    width: 40%;
    margin-right: 10%;

}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content{
    padding: 8px 8px;
    font-size: 13px;
}
.orderTemplate .ant-table.ant-table-small{
    font-size: 13px!important;
}
@media screen{

}
@media print {
@page { size: portrait;}
}
