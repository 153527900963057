
.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.login-logo {
    display: block;
    width: 116px;
    height: 28px;
    background-image: url(../../assets/qingos-logo.png);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 20px auto;
}

.login-container {
    padding: 20px;
    width: 340px;
    box-shadow: -5px 0 5px #ccc, /*左边阴影*/ 5px 0 5px #ccc, /*右边阴影*/ 0 -5px 5px #ccc, /*顶部阴影*/ 0 5px 5px #ccc; /*底边阴影*/;
    border-radius: 10px;
    background-color: #FFF;
}

.login-container-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}


.full-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    /*height: 500px;*/
    width: 100%;
}
.copyright{
    margin-top: 100px;
    color: #666;
    /*font-size: 12px;*/
}
.copyright a{
    color: #666;
    text-decoration: underline;
}
