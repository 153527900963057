/* 设置根元素的字体大小为16px */
html {
    font-size: 16px;
  }
  /* 在小于等于768px的屏幕上，将根元素的字体大小设置为14px */
  @media (max-width: 865px) {
    html {
      font-size: 14px;
    }
  }
  /* 在小于等于480px的屏幕上，将根元素的字体大小设置为12px */
  @media (max-width: 480px) {
    html {
      font-size: 12px;
    }
  }
  .min-width {
    margin: 0 auto;
    padding: 0 4% ;
  }
  .container {
    max-width: 75rem;
    margin: 0 auto;
  }
  .app-body {
    min-height: 96vh;
    background-color: #191a26;
  }
  .parting-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    border: solid 1px #ffffff;
    opacity: 0.1;
    margin: 20px 0;
  }
  .overly {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  .pointer {
    cursor: pointer;
  }
  .none {
    display: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .xtx-form .submit {
    width: 320px;
    border-radius: 4px;
    background: #36c0bb;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    display: block;
    margin: 0 auto;
  }
  .color {
    color: #36c0bb;
  }
  .color[type="plus_month"],
  .color[type="plus_year"] {
    color: #ff9000;
  }
  .color[type="basic_month"],
  .color[type="basic_year"] {
    color: #36c0bb;
  }
  .update-btn-border {
    border: solid 1px #36c0bb;
    color: #36c0bb;
  }
  .update-btn-border[type="plus_month"],
  .update-btn-border[type="plus_year"] {
    border: solid 1px #ff9000;
    color: #ff9000;
  }
  .update-btn-border[type="basic_month"],
  .update-btn-border[type="basic_year"] {
    border: solid 1px #36c0bb;
    color: #36c0bb;
  }
  .bgc-color {
    background-color: #fefefe;
  }
  .bgc-color[type="plus_month"],
  .bgc-color[type="plus_year"] {
    background-color: #ff9000;
  }
  .bgc-color[type="basic_month"],
  .bgc-color[type="basic_year"] {
    background-color: #36c0bb;
  }
  .orange {
    color: #ff9000;
  }
  .green {
    color: #00c57d;
  }
  .white {
    color: #fefefe;
  }
  .cyan {
    color: #36c0bb;
  }