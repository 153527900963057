@import url('../assets/styles/base.css');

.titleContainer{
    width: 100%;
    height: 3.85rem;
    padding: 0 1.25rem;
    background-color: #001529;

    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleLeft,
.titleLeft ul {
  display: flex;
  align-items: center;
}
.titleLeft ul  button{
    padding: .25rem .625rem;
    margin-right: .9375rem;
    border-radius: 10px;
    background-color: transparent;
    transition-duration: 0.3s;
    font-size: 1rem;
}
.titleLeft ul  button:hover{
    background-color: #60616b;
}
.titleLeft ul .check-btn{
    background-color: #60616b;
}

.logo{
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1.875rem;
}