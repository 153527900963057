/* 水平居中和垂直居中 */
.center {
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
}
.flex{
    display: flex;
}
/* 水平居中 */
.h-center {
    display: flex;
    justify-content: center;
    /* 水平居中 */
}

/* 垂直居中 */
.v-center {
    display: flex;
    align-items: center;
    /* 垂直居中 */
}

/* 两侧对齐 */
.space-between {
    display: flex;
    justify-content: space-between;
    /* 两侧对齐 */
}

/* 两侧对齐和垂直居中 */
.align-center {
    display: flex;
    justify-content: space-between;
    /* 两侧对齐 */
    align-items: center;
    /* 垂直居中 */
}

/* 垂直两侧对齐 */
.v-space-between {
    display: flex;
    flex-direction: column;
    /* 纵向排列 */
    justify-content: space-between;
    /* 垂直两侧对齐 */
    height: 100%;
    /* 100%高度 */
}
.flex-shrink{
    flex-shrink: 0;
}