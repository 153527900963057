@import url(./global.css);
@import url('./flex.css');

/* 把所有标签的内外边距清零 */
* {
  margin: 0;
  padding: 0;
  /* css3盒子模型 */
  box-sizing: border-box;
}

/* em 和 i 斜体的文字不倾斜 */
em,
i {
  font-style: normal;
}
ul{
  margin-bottom: 0;
}
/* 去掉li 的小圆点 */
li {
  list-style: none;
}

img {
  /* border 0 照顾低版本浏览器 如果 图片外面包含了链接会有边框的问题 */
  border: 0;
  /* 取消图片底侧有空白缝隙的问题 */
  vertical-align: middle;
}

button {
  /* 当我们鼠标经过button 按钮的时候，鼠标变成小手 */
  cursor: pointer;
}

a {
  text-decoration: none;
}

h1,
h2 {
  user-select: none;
}

button,
input {
  /* "\5B8B\4F53" 就是宋体的意思 这样浏览器兼容性比较好 */
  /* font-family: Roboto-Regular, Heiti SC, tahoma, arial, Hiragino Sans GB, "\5B8B\4F53", sans-serif; */
  /* 默认有灰色边框我们需要手动去掉 */
  border: 0;
  /* outline: none; */
}

/* ::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

body {
  /* CSS3 抗锯齿形 让文字显示的更加清晰 */
  /* -webkit-font-smoothing: antialiased; */
  
  font: 100%/1.5 sans-serif;
  color: #666;
  background-color: #191a26;
}

textarea,
select {
  /* 去除边框 */
  border: none;
  /* 去除选中后的边框 */
  outline: none;
}

.hide,
.none {
  display: none;
}

/* 清除浮动 */
.clearfix:after {
  visibility: hidden;
  clear: both;
  display: block;
  content: ".";
  height: 0;
}
