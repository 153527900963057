

.filter-header {
    background: #fff;
    position: relative;
    left: 100;
    top: 0;
    z-index: 1;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
