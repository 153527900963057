@import url('../../assets/styles/base.css');

#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    max-width: 980px;
    margin: 0 auto;
}

.site-item-container {
    width: 100%;
    min-height: 2.5rem;
    margin-top: 1.875rem;
    background-color: #fff;
    padding: 1.25rem 1.875rem;
    border-radius: 15px;
}

.site-item-container .item-box-title {
    font-size: 1.125rem;
    font-weight: 800;
    margin-bottom: 1.25rem;
}

.sel-addr {
    border-width: 1px;
    border-radius: 3px;
    border-color: orangered;
    border-style: solid;
    margin: 3px;
    padding: 10px;
    width: 100%;
}

.submit-btn{
    width: 10rem;
    height:3.4375rem;
    border-radius: 10px;
    margin-top: 1.875rem;
    background-color: #1890ff;
    font-size: 1.25rem;
    color: #fff;
    transition: all 0.2s ease-in-out;
}


/* cardInfo */
.site-user-info {
    display: flex;
    align-items: center;
}

.site-user-logo {
    font-size: 4.375rem;
    color: #dbdbdb;
    margin-right: 1.5625rem;
}

.site-user-name-box {
    display: flex;
}

.user-info-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 3.25rem;
    margin-left: .625rem;
}

/* 收件信息 */
.addressInfo textarea.ant-input {
    min-width: 37.5rem !important;
}

.addressInfo .address_cascader {
    width: 18.75rem;
    border-radius: 20px;
}

.addressInfo .ant-input {
    min-width: 18.75rem;
}

/* PO 采购订单 purchase order*/

.item-PO .table_product_cascader {
    width: 100%;
    max-width: 35rem;
}

.item-PO .mobileTable {
    display: none;
}

/* invoice 合同与发票 */
.invoice-layout {
    display: flex;
    margin-bottom: 1.25rem;
}

.invoice-title {
    font-size: 1rem;
    width: 5rem;
    margin-right: 1.875rem;
}

.table_price {
    display: none;
}

.invoice-layout textarea,
.invoice-layout button,
input {
    max-width: 31.25rem;
}
.invoice-info .ant-col-4{
    max-width: 100%;
}

/* 覆盖antd */
.site-item-container .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.site-item-container .ant-modal-header,
.site-item-container .ant-modal-content,
.site-item-container .ant-input-number {
    border-radius: 8px;
    overflow: hidden;
}

.site-item-container .ant-input {
    border-radius: 8px;
}
/* - */
.payment .ant-col-4{
    max-width: 40%;
}
/* ========== */
/* ========== */
/* ipad */
@media screen and (max-width: 900px) {
    .item-PO .table_product_cascader {
        width: 100%;
        max-width: 20rem;
    }

}
/* 适配移动 */
@media screen and (max-width: 450px) {

    /* cardInfo */
    .site-user-logo {
        font-size: 2.5rem;
        color: #dbdbdb;
        margin-right: 1rem;
    }

    .user-info-name {
        height: 3.25rem;
        margin-left: .625rem;
    }

    /* 收件信息 */
    .addressInfo textarea.ant-input {
        min-width: 18.75rem !important;
    }

    .addressInfo .ant-input {
        min-width: 9.375rem;
    }

    .addressInfo .address_cascader {
        width: 23rem;
    }

    /* PO 采购订单 purchase order*/

    .item-PO .mobileTable {
        display: block;
        width: 100%;
        min-width: 18.75rem;
        background-color: #FAFAFA;
        padding: .625rem;
        margin-bottom: .625rem;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .item-PO .webTable {
        display: none;
    }


    /* invoice 合同与发票 */
    .invoice-layout {
        flex-wrap: wrap;
    }

    .invoice-title {
        font-size: 1rem;
        width: 11.25rem;
        margin-bottom: .625rem;
    }

    .submit-btn{
       margin-left: -5rem;
    }

}